<script setup>
import 'vue3-carousel/carousel.css'
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'
import { defineEmits, onUnmounted, ref } from 'vue';

const carouselConfig = {
  itemsToShow: 3,
  wrapAround: true,
  autoplay: 3000,
  pauseAutoplayOnHover: true,
}

if (window.screen.width < 900)
  carouselConfig.itemsToShow = 1;

const slides = [
  /*{
    img: new URL('/src/assets/images/bk.png', import.meta.url).href,
    desc: new URL('/src/assets/images/testvid.mp4', import.meta.url).href,
    type: 'video'
  },*/
  {
    img: new URL('/src/assets/images/feuillette.png', import.meta.url).href,
    desc: new URL('/src/assets/images/feuillette.MP4', import.meta.url).href,
    type: 'video',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/paul.png', import.meta.url).href,
    desc: new URL('/src/assets/images/paul.MP4', import.meta.url).href,
    type: 'video',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/faiparla.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/alice.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: true,
  },
  {
    img: new URL('/src/assets/images/paradis.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/flat.jpg', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/bk.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  
  {
    img: new URL('/src/assets/images/bufallo.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/basilic.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/ninkasi.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: true,
  },
  {
    img: new URL('/src/assets/images/forest.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/grotte.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/foodcourt.jpg', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/phood.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  }, 
  {
    img: new URL('/src/assets/images/crepecarrre.jpg', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/woko.jpg', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/seven.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
  {
    img: new URL('/src/assets/images/rossi.png', import.meta.url).href,
    desc: ``,
    type: 'img',
    bgwhite: false,
  },
]

const emit = defineEmits(['initAnimation'])

emit('initAnimation');

const downloadPdf = () => {
  const link = document.createElement('a');
  link.href = '/IDOINE TRANSACTION CONSEIL Certificat Qualiopi -RNQ 3880-225 DS 02 C2.pdf';
  link.download = 'IDOINE TRANSACTION CONSEIL Certificat Qualiopi -RNQ 3880-225 DS 02 C2.pdf';
  link.click();
}

const actualTooltip = ref(1);

const tooltipAutoChange = setInterval(() => {
  actualTooltip.value = actualTooltip.value + 1;

  if (actualTooltip.value > 8)
    actualTooltip.value = 1;

}, 5000)

onUnmounted(() => {
  clearInterval(tooltipAutoChange);
})

</script>

<template>
  <div class="home">
    <div class="container first-container">
      <img class="background" src="@/assets/images/background.png"/>
      <div class="background-filter"></div>
      <div class="title">
        <h1>Idoine formation</h1>
        <h2><strong>Spécialiste</strong> de la <strong>formation</strong> dans les secteurs de la
        <strong>restauration</strong> & de la <strong>vente</strong></h2>
      </div>
      <div class="icon-list">
        <div :class="'pictogram ' + (actualTooltip === 1 ? 'active' : '')" @mouseover="actualTooltip = 1" data-tooltip="Engagement">
          <img src="@/assets/images/Smile.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 2 ? 'active' : '')" @mouseover="actualTooltip = 2" data-tooltip="Réussite">
          <img src="@/assets/images/Thumbs up.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 3 ? 'active' : '')" @mouseover="actualTooltip = 3" data-tooltip="Evolutions">
          <img src="@/assets/images/Trending up.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 4 ? 'active' : '')" @mouseover="actualTooltip = 4" data-tooltip="Flexibilité">
          <img src="@/assets/images/Watch.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 5 ? 'active' : '')" @mouseover="actualTooltip = 5" data-tooltip="Formateurs expérimentés">
          <img src="@/assets/images/User check.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 6 ? 'active' : '')" @mouseover="actualTooltip = 6" data-tooltip="Disponibilité">
          <img src="@/assets/images/Calendar.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 7 ? 'active' : '')" @mouseover="actualTooltip = 7" data-tooltip="Reconnue">
          <img src="@/assets/images/Award.png"/>
        </div>
        <div :class="'pictogram ' + (actualTooltip === 8 ? 'active' : '')" @mouseover="actualTooltip = 8" data-tooltip="Motivation">
          <img src="@/assets/images/Sun.png"/>
        </div>
      </div>
    </div>
    <div class="container second-container">
      <div class="card-valeurs">
        <h2 class="card-title">Formations sur mesure</h2>
        <p>
          <i class="fa-solid fa-gear" style="color: #ffffff;"></i> - Personnalisation<br>
          <i class="fa-solid fa-chart-simple" style="color: #ffffff;"></i> - Accompagnement structuré<br>
          <i class="fa-solid fa-leaf" style="color: #ffffff;"></i> - Impact humain et durable
        </p>
      </div>
      <div class="card-valeurs">
        <h2 class="card-title">Accompagnés par des professionnels passionnés</h2>
        <p>
          <i class="fa-solid fa-suitcase" style="color: #ffffff;"></i> - Expertise métier<br>
          <i class="fa-solid fa-handshake" style="color: #ffffff;"></i> - Accompagnement personnalisé<br>
          <i class="fa-solid fa-heart" style="color: #ffffff;"></i> - Passion et engagement
        </p>
      </div>
      <div class="card-valeurs">
        <h2 class="card-title">Financement à 100%</h2>
        <p>
          <i class="fa-solid fa-money-check-dollar" style="color: #ffffff;"></i> - Financement simplifié<br>
          <i class="fa-solid fa-shuffle" style="color: #ffffff;"></i> - Formation flexible<br>
          <i class="fa-solid fa-book" style="color: #ffffff;"></i> - Formation théorique et pratique
        </p>
      </div>
    </div>
    <div class="container third-container">
      <img class="background" src="@/assets/images/background2.png"/>
      <div class="background-filter"></div>
      <div class="detail-grid">
        <div class="detail-card">
          <div @click="downloadPdf" style="cursor: pointer;">
             <img src="@/assets/images/logo-qualiopi.png" />
              <p> 
                La certification qualité a été délivrée
                au titre de la ou des catégories
                d'actions suivantes :<br>
                <strong>ACTION DE FORMATION</strong>
              </p>
          </div>
          <p>
            Idoine Formation est <strong>certifié Qualiopi</strong> !<br/>
            Qualiopi est une certification unique du <strong>Ministère du Travail</strong>, obligatoire pour accéder aux <strong>financements publics et mutualisés</strong>.
          </p>
        </div>
        <div class="detail-card">
          <p>100%</p>
          <span>Satisfaction employeur</span>
        </div>
        <div class="detail-card">
          <p>100%</p>
          <span>Satisfaction partenaires</span>
        </div>
        <div class="detail-card">
          <p>92%</p>
          <span>Satisfaction apprenants</span>
        </div>
        <!-- <div class="detail-card">
          <p>153h</p>
          <span>formations</span>
        </div>
        <div class="detail-card">
          <p>1600</p>
          <span>personnes formées</span>
        </div> -->
      </div>
      <div class="detail-text">
        <p>
          Nos formations sont conçues pour garantir votre réussite professionnelle. Qualité, expertise et
          impact : tout est mis en œuvre pour vous faire progresser.<br/><br/>
          ✅ Des taux de satisfaction exceptionnels qui témoignent de l’excellence de nos formations.<br/>
          ✅ Des centaines de professionnels formés grâce à des milliers d’heures d’expertise.<br/>
          ✅ Un investissement permanent pour vous offrir des contenus pédagogiques optimisé.<br/>
          ✅ Des résultats concrets : développez vos compétences et atteignez vos objectifs.<br/>
        </p>
      </div>
      <p class="detail-from">depuis <strong>2020</strong>...</p>
    </div>
    <div class="container fourth-container">
      <div class="director-img">
        <img src="@/assets/images/dominique.jpeg"/>
      </div>
      <div class="director-words">
        <h2>Pourquoi j’ai créé IDOINE FORMATIONS</h2>
        <p>
          “Avec <span class="red-text">27 ans d’expérience</span> en <span class="red-text">formation</span>, <span class="red-text">vente</span> et <span class="red-text">management</span> dans l’univers de la <span class="red-text">restauration</span>, j’ai eu à cœur de créer <span class="red-text">IDOINE FORMATION</span>, un <span class="red-text">organisme de formation</span> dédié à l’accompagnement des <span class="red-text">entreprises</span>, <span class="red-text">dirigeants</span> et <span class="red-text">équipes</span>.<br><br>

Notre mission ? Concevoir des <span class="red-text">formations sur mesure</span>, adaptées aux besoins des <span class="red-text">TPE</span>, <span class="red-text">franchises</span> et <span class="red-text">grands groupes</span>, avec une approche pratique et ancrée dans la réalité du terrain. Nous allions <span class="red-text">expertise métier</span>, <span class="red-text">pédagogie immersive</span> et <span class="red-text">accompagnement personnalisé</span> pour garantir des <span class="red-text">résultats concrets</span>.<br><br>

En parallèle, j’interviens comme <span class="red-text">conseil opérationnel</span> auprès d’<span class="red-text">entreprises</span> et de <span class="red-text">créateurs</span>, et je partage mon expérience en tant que <span class="red-text">mentor à l’EM Lyon</span>.<br><br>

Chez <span class="red-text">IDOINE FORMATION</span>, <span class="red-text">l’humain</span> est au cœur de notre démarche. Plus qu’un <span class="red-text">programme de formation</span>, nous construisons des <span class="red-text">solutions adaptées</span> à vos enjeux.<br><br>

Échangeons sur vos besoins et donnons ensemble un <span class="red-text">nouvel élan à vos compétences</span>.  
”<br/>
          </p>
          <p>Dominique Dura<br/>Directeur - IDOINE FORMATION<br/>
        </p>
      </div>
    </div>
    <div class="container fifth-container">
      <img class="background" src="@/assets/images/bg-ref.jpg"/>
      <div class="background-filter"></div>
      <Carousel v-bind="carouselConfig">
        <Slide v-for="(slide, slideIndex) in slides" :key="slideIndex">
          <div class="carousel-item">
            <div class="carousel-item-img">
              <img v-if="slide.bgwhite == true" class="bg-white" :src="slide.img"/>
              <img v-else :src="slide.img"/>
            </div>
            <p class="carousel-item-desc">
              <template v-if="slide.type == 'img'">{{ slide.desc }}</template>
              <video width="240" v-else :src="slide.desc" controls  />
            </p>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
    <div class="container six-container">
      <h1>Partenaires</h1>
      <div class="partners-list">
        <div class="partner-logo">
          <img src="@/assets/images/akto.jpg"/>
        </div>
        <div class="partner-logo">
          <img src="@/assets/images/pole-emploi.jpg"/>
        </div>
        <div class="partner-logo">
          <img src="@/assets/images/agefos.jpg"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
}
</script>

<style lang="scss">
.home{
  .container {
    --vc-pgn-border-radius: 50%;
    --vc-pgn-height: 16px;
    --vc-pgn-margin: 0px 5px;
    --vc-pgn-background-color: #FFFFFF;
    --vc-pgn-active-color: #E10600;

    &.first-container {
      .icon-list {
        display: flex;
        justify-content: space-around;
        width: 90vw;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        .pictogram {
          position: relative;
          width: 50px;
          height: 50px;
          cursor: pointer;
          transition: transform 0.3s ease-in-out;
          @media screen and (max-width: 900px) {
            width: 30px;
            height: 30px;
          }
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &::after {
            content: attr(data-tooltip);
            visibility: hidden;
            background-color: #fff;
            color: #000;
            text-align: center;
            padding: 6px 10px;
            border-radius: 5px;
            position: absolute;
            bottom: 110%;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            opacity: 0;
            transition: opacity 0.8s ease-in-out;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
            font-weight: 400;
            font-size: 8px
          }
          &.active::after {
            @media screen and (min-width: 900px) {
              visibility: visible;
            }
            opacity: 1;
          }
          &.active {
            transform: scale(2);
            filter: brightness(1.2);
          }
        }
      }
    }

    &.second-container {
      position: relative;
      height: 90vdh;

      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 40px 0;
      }

      &.animation {
        .card-valeurs {
          animation: .7s linear 1s infinite alternate flow;
          &:first-child {
            left: 20%;
          }
          &:nth-child(2) {
            top: 60%;
            animation: .7s linear infinite alternate flow2 1s;
          }
          &:nth-child(3) {
            right: 20%;
          }
        }

        @keyframes flow {
          from {
            top: 40%;
          }
          to {
            top: 39.2%;
          }
        }
        @keyframes flow2 {
          from {
            top: 60%;
          }
          to {
            top: 59.2%;
          }
        }
      }

      .card-valeurs {
        position: absolute;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: linear-gradient($blue, $darkblue);
        color: white;
        width: 25%;
        height: auto;
        padding: 15px;
        transform: translate(-50%, -50%);
        z-index: 10;
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: linear-gradient($blue, $darkblue);
          left: 0;
          top: 0;
          z-index: -1;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: calc(100% + 8px);
          background-color: $red;
          left: 0;
          top: -8px;
          z-index: -2;
          border-radius: 8px;
        }
        &:first-child {
          left: -20%;
          transition: left 1s ease;
          top: 40%;
        }
        &:nth-child(2) {
          left: 50%;
          top: -30%;
          transition: top 1s ease;
          .card-title {
            font-size: 23px;
          }
        }
        &:nth-child(3) {
          right: -20%;
          top: 40%;
          transition: right 1s ease;
          transform: translate(50%, -50%);
        }

        @media screen and (max-width: 900px) {
          position: initial;
          transform: translate(0, 0)!important;
          top: initial;
          left: initial;
          right: initial;
          width: 90%;
          height: auto;
        }

        h2 {
          text-align: center;
          font-size: 28px;
          margin-bottom: 70px;
        }
        p {
          font-size: 20px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          br {
            display: block;
            content: " ";
            height: 20px;
          }
        }
      }
    }
    &.third-container {

      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      gap: 20px;
      padding: 20px 0;

      @media screen and (max-width: 900px) {
        min-height: 150vh;
        justify-content: center;
      }

      .detail-grid {
        display: flex;
        gap: 10px;
        width: 80%;
        height: 70%;
        z-index: 10;
        flex-wrap: wrap;
        @media screen and (max-width: 900px) {
          position: initial;
          transform: translate(0, 0);
        }
      }

      &.animation {
        .detail-card {
          transform: translate(0, 0)!important;
        }
      }

      .detail-card {
        background: white;
        border: 2px solid $red;
        border-spacing: 10px;
        border-radius: 10px;
        transition: transform 1s ease;
        div{
          cursor: pointer;
        }
        div p{
          padding-left: 10px;
          padding-right: 5px;
          font-size: 22px !important;
          margin-top: 0px;
          margin-bottom: 5px;
        }
        div p br {
          height: 1px !important;
        }
        &:first-child {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          transform: translateY(-1000%);
          @media screen and (max-width: 900px) {
            flex-direction: column;
            padding: 10px;
          }
          img {
            height: 50%;
            @media screen and (max-width: 900px) {
              height: auto;
              width: 100%;
            }
          }
          p {
          
            font-size: 24px;
            @media screen and (max-width: 900px) {
              font-size: 16px;
            }
            br {
              display: block;
              content: " ";
              height: 20px;
            }
          }
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          flex: 1 1 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 900px) {
            padding: 10px;
          }
          &:nth-child(2){
            transform: translateX(-500%);
          }
          &:nth-child(3){
            transform: translateX(-1000%);
          }
          &:nth-child(4){
            transform: translateX(1000%);
          }
          p {
            @media screen and (max-width: 900px) {
              font-size: 30px;
            }
            font-size: 48px;
            font-weight: bold;
            margin: 0;
          }
          span {
            @media screen and (max-width: 900px) {
              font-size: 20px;
              text-wrap: nowrap;
            }
            font-size: 24px;
          }
        }
        // &:nth-child(5), &:last-child {
        //   flex: 1 1 40%;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   align-items: center;
        //   transform: translateY(1000%);
        //   p {
        //     font-size: 96px;
        //     font-weight: bold;
        //     margin: 0;
        //   }
        //   span {
        //     font-size: 30px;
        //   }
        //   &:last-child {
        //     p {
        //       color: $red;
        //     }
        //   }
        // }
      }
      .detail-text {
        z-index: 10;
        color: $white;
        width: 80%;
        @media screen and (max-width: 900px) {
          font-size: 20px;
        }
      }
      .detail-from {
        z-index: 10;
        font-size: 40px;
        color: white;
        margin: 0;
        @media screen and (max-width: 900px) {
          text-wrap: nowrap;
          position: initial;
          transform: translate(0, 0);
        }
        strong {
          font-size: 64px;
          color: $red;
        }
      }
    }

    &.fourth-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: auto;
      padding: 30px 0;
      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
      &.animation {
        .director-img {
          transform: translate(0, 0) !important;
        }
        .director-words {
          p{
            opacity: 1;
          }
        }
      }
      .director-img {
        @media screen and (max-width: 900px) {
          width: 50%;
        }
        width: 30%;
        position: relative;
        overflow: hidden;
        aspect-ratio: 1/1;
        border-radius: 50%;
        border: 3px solid $red;
        transition: transform 1s ease;
        transform: translateX(-200%);
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
        }
      }
      .director-words {
        width: 50%;
        @media screen and (max-width: 900px) {
          width: 90%;
        }
        h2 {
          font-size: 48px;
          @media screen and (max-width: 900px) {
            font-size: 38px;
          }
        }
        p {
          opacity: 0;
          transition: opacity 1.5s ease;
          font-weight: 300;
          &:nth-child(2) {
            @media screen and (max-width: 900px) {
              font-size: 20px;
            }
            font-size: 24px;
            font-style: italic;
            .red-text {
              color: $red;
            }
          }
          &:nth-child(3) {
            font-size: 20px;
            @media screen and (max-width: 900px) {
              font-size: 16px;
            }
          }
        }
      }
    }
    &.fifth-container {
      .carousel {
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
        height: 100%;
        @media screen and (max-width: 900px) {
          top: initial;
          transform: translateY(0);
          height: 100vh;
        }
        
        .carousel__pagination {
          bottom: 20px!important;
        }
        .carousel-item {
          width: 100%;
          padding: 0px;
          font-size: 22px;
          font-style: italic;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .bg-white {
              background-color: white;
            }
          .carousel-item-img img{
            width: 30%;
          }
        }
        .carousel-item-content {
          background: red;
        }
        .carousel__viewport {
          height: 100%;
          .carousel__track {
            height: 100%;
          }
        }
      }
    }
    &.six-container {
      h1 {
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translateX(-50%);
        font-size: 65px;
        @media screen and (max-width: 900px) {
          margin: 0;
          top: 10%;
        }
        &::after {
          content: " ";
          display: block;
          width: 50%;
          height: 2px;
          background: $red;
          margin: auto;
        }
      }
      .partners-list {
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        @media screen and (max-width: 900px) {
          flex-direction: column;
          top: 20%;
        }
        .partner-logo {
          position: relative;
          overflow: hidden;
          width: 20%;
          height: auto;
          aspect-ratio: 1/1;
          @media screen and (max-width: 900px) {
            width: 50%;
          }
          img {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
</style>
